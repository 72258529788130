import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from '../assets/airliftLogo.png';
import miniLogo from '../assets/airliftMiniLogo.png';
import './Header.css';

const Header = () => {
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [isScrollingUp, setIsScrollingUp] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollTop = window.pageYOffset;

            setIsScrollingUp(currentScrollTop > lastScrollTop);
            setLastScrollTop(currentScrollTop);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [lastScrollTop]);

    const handleLogoSrc = () => {
        if (window.innerWidth <= 768) {
            return miniLogo;
        } else {
            return logo;
        }
    };

    return (
        <header className={`header ${isScrollingUp ? "scrolling" : ""}`}>
            <Link to="/">
                <img src={handleLogoSrc()} alt="Airlift Logo" className="logo" />
            </Link>
            <Link to="/contact" className="contact-link">CONTACT US</Link>
        </header>
    );
};

export default Header;
