import React, { useState, useEffect } from "react";
import "./ContactPage.css";
import OffRoom from "../assets/carousel/officeRoom.png";
import WrkSpace from "../assets/carousel/workspace.png";
import OffTel from "../assets/carousel/officeTelephone.png";
import InstagramIcon from "../assets/icons/instagram.png";
import LinkedinIcon from "../assets/icons/linkedin.png";
import YoutubeIcon from "../assets/icons/youtube.png";

const ContactPage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const carouselImages = [OffRoom, WrkSpace, OffTel];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === carouselImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [carouselImages.length]);

  return (
    <div className="contact-page">
      <img
        //src={carouselImages[currentImageIndex]}
        src={OffRoom}
        alt="Office Room"
        className="office-image"
      />
      <div className="contact-container">
        <div className="contact-content">
          <p>1185 Campbell Avenue<br />San Jose, CA 95126</p>
          <p>Phone: 844 960 4141</p>
          <p>Fax: 408 982 3383</p>
          <p><a href="mailto:mel@airlift.systems">info@airlift.systems</a></p>
          <div className="social-icons">
            <a
              href="https://www.instagram.com/airlift.systems/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={InstagramIcon}
                alt="Instagram account of the Company"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/airlift-systems/about/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={LinkedinIcon} alt="LinkedIn account of the Company" />
            </a>
            <a
              href="https://youtu.be/gPnV0n2TVYA?si=WrXFliDJCZPWoO6F"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={YoutubeIcon} alt="Youtube video of Company" />
            </a>
          </div>
        </div>
        <div className="map-container">
          <iframe
            className="map-iframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3171.629664086712!2d-121.92621428478955!3d37.30719597985043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fcbc1f7e5650b%3A0xe6e69f501299b420!2s1185%20Campbell%20Ave%2C%20San%20Jose%2C%20CA%2095126%2C%20USA!5e0!3m2!1sen!2s!4v1624446788250!5m2!1sen!2s&style=feature:all|element:labels|visibility:off&style=feature:all|element:geometry|color:0x2b2b2b"
            title="Location Map"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;