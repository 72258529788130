import React from "react";
import videoSrc from '../assets/airliftvid.mp4';
import './Video.css';

const Video = () => {
    return (
        <div className='main'>
            <video src={videoSrc} autoPlay loop muted className="Video"/>
        </div>
    );
}

export default Video;
