import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Video from './components/Video';
import Header from './components/Header';
import Body from './components/Body';
import ContactPage from './Pages/ContactPage';

function App() {
    return (
        <Router>
            <div className="App">
                <Header />
                <Routes>
                    <Route path="/" element={<>
                        <Video />
                        <Body />
                    </>} />
                    <Route path="/contact" element={<ContactPage />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
