import React from "react";
import './Body.css';
import Lux from '../assets/airliftLuxury.png';
import Com from '../assets/airliftCompact.png';
import Eff from '../assets/airliftEfficiency.png';

const Body = () => {
    return (
        <div className='body'>
            <div className="image-row">
                <div className="left">
                    <img src={Lux} alt="Luxury Airlift" className="body-image" />
                    <div className="text-box-container">
                        <div className="text-box">
                            <h1 className="title">Luxurious</h1>
                            <p className="definition">Experience the opulance of the Airlift, and let the cascading pillar of air elevate you to a new height of luxurious living.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="image-row">
                <div className="left">
                    <img src={Com} alt="Compact Airlift" className="body-image" />
                    <div className="text-box-container">
                        <div className="text-box">
                            <h1 className="title">Compact</h1>
                            <p className="definition">A seamless fit for a life of comfort, the Airlift will find itself a hidden part of everything that is you.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="image-row">
                <div className="left">
                    <img src={Eff} alt="Efficient Airlift" className="body-image" />
                    <div className="text-box-container">
                        <div className="text-box">
                            <h1 className="title">Efficient</h1>
                            <p className="definition">The only reliable source of ease, the Airlift can only hasten the life of adventure, lifting your burdens in blissful silence.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Body;